.splash {
  height:100vh;
  width: 100%;
  background-color: white;
}
.logoImage{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 300px;
}