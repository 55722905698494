.aboutUs {
  margin-left: 30px;
  margin-top: 30px;
}
.card {
  flex-direction: row;
  height: 50vh;
  margin-right: 20px;
  border: none;
  background-color: transparent;
}
.card img {
  width: 30%;
  border-radius: calc(0.25rem - 1px);
}
.card-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin: 0px;
  padding: 0px;
}
.card-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 163.52%;
  color: #787878;
  text-indent: 50px;
}
.card-body {
  margin:60px 80px;
}
