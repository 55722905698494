#carouselExampleRide {
  margin: 0px 20px;
  border-radius: calc(0.25rem - 1px);
}
.carous-image {
  width: 100%;
  height: 42rem;
  object-fit: cover;
  border-radius: 25px;
  padding: 0px 10px;
}
#text {
  padding-left: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 70px;
}
.carousel-control-prev {
  margin: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.363);
}

.carousel-control-next {
  margin: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.363);
}
