nav {
  background-color: #77bb3f;
}

.navbar {
  padding: 20px;
}
.logo-space #text {
  font-size: 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.navLogo {
  height: 80px;
  border-radius: 10%;
}